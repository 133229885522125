import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StoreDatasService } from 'src/app/store-datas.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StoreDetailsService {
  private apiBaseUrl = `${environment.apiUrl}`;
  private apiNode = `${environment.apiUrlNode}`;
  getMenuDetails: any;

  constructor(
    private http: HttpClient,
    private storeDatas: StoreDatasService
  ) {}

  public storeDetails(data: any) {
    return this.http.post(`${this.apiBaseUrl}`, {
      name: 'viewStoreNew',
      param: {
        unqId: data,
      },
    });
  }

  public storeDetailsEdit(val: any) {
    return this.http.post(this.apiBaseUrl, val);
  }

  public storeDelete(data: any) {
    this.storeDatas.removeStoreList('1');
    return this.http.post(`${this.apiBaseUrl}`, {
      name: 'deleteStoreNew',
      param: {
        unqId: data,
      },
    });
  }

  public menuListing(data: any) {
    return this.http.post(`${this.apiBaseUrl}`, {
      name: 'storemenulisting',
      param: {
        storeId: data,
      },
    });
  }

  public createOnboard(data: any, image, formData, type) {
    return this.http.post(`${this.apiBaseUrl}`, {
      name: 'onBoard',
      param: {
        storeId: data,
        type: type,
        url: image,
        title: formData.title,
        description: formData.description,
      },
    });
  }

  public listOnboard(data: any, type) {
    return this.http.post(`${this.apiBaseUrl}`, {
      name: 'onBoard',
      param: {
        storeId: data,
        type: type,
        url: '',
        description: '',
        title: '',
      },
    });
  }

  public editOnboard(data: any, id, image, formData, type) {
    return this.http.post(`${this.apiBaseUrl}`, {
      name: 'onBoard',
      param: {
        storeId: data,
        id: id,
        type: type,
        url: image,
        title: formData.title,
        description: formData.description,
      },
    });
  }

  public deleteOnboard(data: any, id, type) {
    return this.http.post(`${this.apiBaseUrl}`, {
      name: 'onBoard',
      param: {
        storeId: data,
        id: id,
        type: type,
        url: '',
        description: '',
        title: '',
      },
    });
  }

  public getStoreDetails(data: any) {
    return this.http.post(`${this.apiNode}storeName/storeview`, data);
  }
}
