// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.moonhive-server.in.net/groceryapp-api/v1/',
  // apiUrl: 'http://localhost/moonhive/ds-dashboard-php-apis/v1/',
  apiUrlNode: 'https://ds-node-api.moonhive-server.in.net/v1/',
  // apiUrlNode: 'http://localhost:3012/v1/',
  apiCommunication: 'https://api-com.dailystore.io/v1/communication',
  fileUploadUrl: 'https://dev-dailystore-api.moonhive.in/v1/banner_new.php',
  firebase: {
    apiKey: 'AIzaSyBw0bfxcaGKqViXCig4ZgPR2vINgpXz-EU',
    authDomain: 'push-notification-9c1e9.firebaseapp.com',
    projectId: 'push-notification-9c1e9',
    storageBucket: 'push-notification-9c1e9.appspot.com',
    messagingSenderId: '1078589984464',
    appId: '1:1078589984464:web:22e8c19405493d8bd198d5',
    measurementId: 'G-46PP9N1KYE',
    vapidKey:
      'BI17KyjipLdO8rOOCLNauVqyml2bY4LYi3MJwofVjnVXLEMM17Grky0fGTwZs2fU_QiO_QlJ0EsX9taLQ8c1CeU',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';
// Included with Angular CLI.
