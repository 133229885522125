<div class="d-flex justify-content-between">
  <div class="col-lg-6 mx-auto loginContent">
    <!-- Basic login form-->
    <div class="description" style="margin-left: 60px; margin-right: 45px;">
      Welcome to Dailystore, the user-friendly platform that makes it easy to turn your ideas into reality - sign up now and start creating your store app today!
    </div>
    <br>
    <div style="text-align: center; margin-top: 122px;">
      <img src="assets/images/signup.png" style="width: 60%; ">
    </div>
  </div>



  <div class="col-lg-6 mx-auto coloum-2" style="margin-top: -90px;
  background: #FFF;
  min-height: 100vh;
  -webkit-box-shadow: 6px 1px 24px 0px rgba(92,92,92,1);
-moz-box-shadow: 6px 1px 24px 0px rgba(92,92,92,1);
box-shadow: 6px 1px 24px 0px rgba(92,92,92,1);
">
    <div style="text-align: center; margin-top: 88px;" class="green_logo"><img src="assets/images/green_logo.png"></div>
    <!-- Basic login form-->
    <app-toast [config]="toastConfig" *ngIf="isApiReturned" (closed)="toastClosed($event)"></app-toast>
    <!-- Login form-->
    <div class="newstyle">
      <div class="col-2-section">
        <div class="col-2-heading">Sign Up!</div>
        <app-toast [config]="toastConfig" *ngIf="isApiReturned" (closed)="toastClosed($event)"></app-toast>
          <form  style="margin-top: 24px;" [formGroup]="signupForm" (ngSubmit)="signupFormSubmit()" #myform="ngForm">
            <!-- Form Group (Store name)-->
            <div class="form-group">
              <mat-form-field appearance="outline" class="matDesign">
                <mat-label for="inputName" style="color: black">Name</mat-label>
                <input matInput id="inputName" type="text" formControlName="name" required />
              </mat-form-field>
              <span *ngIf="signupForm.get('name').hasError('required') && (signupForm.get('name').touched || submitted)"
                class='error-message text-danger'>Name is required</span>
              <span *ngIf="signupForm.get('name').hasError('pattern') && signupForm.get('name').touched"
                class='error-message text-danger'>Special character and double space are not allowed</span>
            </div>
            <!-- Form Group (Store Email)-->
            <div class="form-group">
              <mat-form-field appearance="outline" class="matDesign">
                <mat-label for="inputEmailAddress" style="color: black">Email</mat-label>
                <input matInput id="inputEmailAddress" type="email" formControlName="email"
                  style="text-transform: lowercase;" required />
              </mat-form-field>
              <span *ngIf="signupForm.get('email').hasError('required') && (signupForm.get('email').touched || submitted)"
                class='error-message text-danger'>Email is required</span>
              <span *ngIf="signupForm.get('email').hasError('pattern') && signupForm.get('email').touched"
                class='error-message text-danger'>Enter the valid Email</span>
            </div>
            <!-- Form Group (Store Phone) -->
            <div class="form-group">
              <mat-form-field appearance="outline" class="matDesign">
                <mat-label for="inputPhoneNumber" style="color: black">Mobile Number</mat-label>
                <input matInput id="inputPhoneNumber" type="text" (keypress)="onlyNumberKey($event)"
                  formControlName="mobile" required />
              </mat-form-field>
              <span *ngIf="signupForm.get('mobile').hasError('required') && (signupForm.get('mobile').touched || submitted)"
                class='error-message text-danger'>Mobile number is required</span>
              <span *ngIf="signupForm.get('mobile').hasError('pattern') && signupForm.get('mobile').touched"
                class='error-message text-danger'>Enter the valid Mobile number</span>
            </div>
            <!-- Form Group (password) -->
            <div class="form-group">
              <mat-form-field appearance="outline" class="matDesign">
                <mat-label style="color: black" for="inputPassword">Password</mat-label>
                <input matInput id="inputPassword" [type]="showpassword ? 'text' : 'password'"
                  placeholder="Enter your password" formControlName="password" required />
                <i (click)="toggleShow()" class="fa-solid fa-eye" *ngIf="!showpassword"></i>
                <i (click)="toggleShow()" class="fa-solid fa-eye-slash" *ngIf="showpassword"></i>
              </mat-form-field>
              <span *ngIf="signupForm.get('password').hasError('required') && (signupForm.get('password').touched || submitted)"
                class='error-message text-danger'>Password is required</span>
              <span *ngIf="signupForm.get('password').hasError('pattern') && signupForm.get('password').touched"
                class='error-message text-danger'>Please enter at least 8 characters with 1 number, 1 alphabet, and 1 special character</span>
            </div>
            <!-- Form Group (confirm password) -->
            <div class="form-group">
              <mat-form-field appearance="outline" class="matDesign">
                <mat-label for="inputConfirmPassword" style="color: black">Confirm Password</mat-label>
                <!-- <input matInput placeholder="confirmpassword" id="inputEmailAddress" type="text" formControlName="password"> -->
                <input matInput id="inputConfirmPassword" [type]="showpassword1 ? 'text' : 'password'"
                  placeholder="Enter your password" formControlName="confirm_password" required />
                <i (click)="toggleShow1()" class="fa-solid fa-eye eye" *ngIf="!showpassword1"></i>
                <i (click)="toggleShow1()" class="fa-solid fa-eye-slash eye" *ngIf="showpassword1"></i>
    
              </mat-form-field>
              <span
                *ngIf="signupForm.get('confirm_password').hasError('Mustmatch') && (signupForm.get('confirm_password').touched || submitted)"
                class='error-message text-danger'>Password is mismatched</span>
            </div>
            <!-- Form Group (create account submit)-->
            <div class=" d-flex align-items-center justify-content-between  mb-0">
              <button class="btn container login" type="submit  "
                >Sign Up</button>
            </div>
          </form>
       
        <div *ngIf="!adminStore" class="separator" style="color: rgba(166, 166, 166, 1);">or</div>
        <div class="d-flex justify-content-center styl2" >
          <div class="cardStyle">
            <div id="buttonDiv"></div>
        
          </div>
          <div >
            <button class="btn btn-facebook" (click)="login()" style="white-space: nowrap; margin: 1px 0px 0px 5px; padding: 10px;">
              <img src="assets/images/facebook.png" style="width: 10px; height: 18px;">
                Login with Facebook
            </button>
          </div>
        </div>
        <div style="text-align: center;" *ngIf="!adminStore">
          <div class="signup">Have a store? <a (click)="goToLogin()" style="color: #4CAF50; cursor: pointer" href="">Go to login
            </a></div>
        </div>
        <div class="terms" *ngIf="!adminStore">
          By continuing, you agree to our Terms of Service and Privacy Policy
        </div>
      </div>
      
    </div>
    
   
  </div>

</div>
