import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  public localCurrentPage = 1;
  public totalPages: number;
  public pageBlock: any = [];

  private paginationBlocks: number;
  private currentPaginationBlock = 1;

  @Input() maxSize: number;
  @Input() totalItems: number;
  @Input() itemsPerPage: number;
  @Input() currentPage: number;


  public pages: any = [];

  @Output() paginate = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    if (this.maxSize) {
      this.totalPages = this.totalItems / this.itemsPerPage;
      this.paginationBlocks = this.totalPages / this.maxSize;
      if (!Number.isInteger(this.paginationBlocks)) {
        this.paginationBlocks = Math.trunc(this.paginationBlocks) + 1;
      }
      for (let i = 0; i < this.totalPages; i++) {
        this.pages.push({ page: i + 1 });
      }
      this.paginateBlocks('next');
    }
  }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['currentPage'] && this.currentPage && this.currentPage !== this.localCurrentPage) {
        this.localCurrentPage = this.currentPage;
      }
    }
  
  private paginateBlocks(direction): void {
    let lastIndex: number;
    let firstIndex: number;
    if (direction === 'next') {
      if (this.localCurrentPage < this.totalPages - this.maxSize) {
        firstIndex = this.localCurrentPage - 1;
      } else {
        firstIndex = this.totalPages - this.maxSize;
      }
      lastIndex = this.localCurrentPage + (this.maxSize - 1);
    } else {
      if (this.localCurrentPage - this.maxSize >= 0) {
        firstIndex = this.localCurrentPage - (this.maxSize);
      } else {
        firstIndex = 0;
      }
      if (this.localCurrentPage < this.maxSize) {
        lastIndex = this.maxSize;
      } else {
        lastIndex = this.localCurrentPage;
      }
    }
    this.pageBlock = this.pages.slice(firstIndex, lastIndex);
  }

  public next(): void {
    if (this.localCurrentPage < this.totalPages) {
      this.localCurrentPage += 1;
      this.paginate.emit(this.localCurrentPage);
      if (this.localCurrentPage > this.pageBlock[this.maxSize - 1].page) {
        this.nextBlock();
      }
    }
  }

  public previous(): void {
    if (this.localCurrentPage > 1) {
      this.localCurrentPage -= 1;
      this.paginate.emit(this.localCurrentPage);
      if (this.localCurrentPage < this.pageBlock[0].page) {
        this.previousBlock();
      }
    }
  }

  public goToPage(e): void {
    this.localCurrentPage = e.page;
    this.paginate.emit(this.localCurrentPage);
  }

  public nextBlock(): void {
    this.paginateBlocks('next');
  }
  public previousBlock(): void {
    this.paginateBlocks('previous');
  }

}
