<div class="d-flex justify-content-between">

  <div class="col-lg-6 mx-auto loginContent">
    <!-- Basic login form-->
   
    <div class="description ml-5 mr-4 mt-5">
      Welcome back - log in to Dailystore pick up <br>
      right where you left off!
    </div>
    <br>
    <div style="text-align: center;margin-top: 20px;">
      <img src="assets/images/new_login.png" style="width: 60%;">
    </div>
  </div>



  <div class="col-lg-6 mx-auto coloum-2" style="margin-top: -90px;
  background: #FFF;
  min-height: 100vh;
  -webkit-box-shadow: 6px 1px 24px 0px rgba(92,92,92,1);
-moz-box-shadow: 6px 1px 24px 0px rgba(92,92,92,1);
box-shadow: 6px 1px 24px 0px rgba(92,92,92,1);
">
    <div style="text-align: center; margin-top: 88px;" class="green_logo"><img src="assets/images/green_logo.png"></div>
    <!-- Basic login form-->
    <app-toast [config]="toastConfig" *ngIf="isApiReturned" (closed)="toastClosed($event)"></app-toast>
    <!-- Login form-->
    <div class="newstyle">
      <div class="col-2-section">
        <div class="col-2-heading">Login!</div>
  
          <form  [formGroup]="loginForm" #myform="ngForm">
          <div >

            <mat-form-field appearance="outline" class="matDesign">
              <mat-label class="emmail" for="contactNumber"
              >Email / Mobile Number</mat-label>
              <input matInput  placeholder="Email / Mobile Number" id="contactNumber" formControlName="phone"required/>
            </mat-form-field>
            <span *ngIf="loginForm.get('phone').hasError('required') && (loginForm.get('phone').touched || submitted)"
              class='error-message text-danger'>Email / Mobile Number is required</span>
            <span *ngIf="loginForm.get('phone').hasError('pattern') && loginForm.get('phone').touched"
              class='error-message text-danger'>Enter the valid Email / Mobile Number</span>
          </div>
          <!-- Form Group (password)-->
          <div >

            <mat-form-field appearance="outline" class="matDesign">
              <mat-label for="loginPassword" class="emmail">Password</mat-label>
              <input matInput id="loginPassword" [type]="showpassword ? 'text' : 'password'"
                placeholder="Enter your password" formControlName="password" required />
              <i (click)="toggleShow()" class="fa-solid fa-eye eye" *ngIf="!showpassword"></i>
              <i (click)="toggleShow()" class="fa-solid fa-eye-slash eye" *ngIf="showpassword"></i>
            </mat-form-field>
            <span
              *ngIf="loginForm.get('password').hasError('required') && (loginForm.get('password').touched || submitted)"
              class='error-message text-danger'>Password is required</span>
          </div>
          <div class="small reset-password"><a routerLink="/guest/forgot_password" style="color: #4CAF50">Reset
              Password?</a></div>
          <div class=" d-flex align-items-center justify-content-between  styl1">
            <button class="btn container login" (click)="loginFormSubmit()">Login</button>
          </div>
        </form>
        <div class="d-flex justify-content-end re1" style="margin-bottom: 20px; margin-top: 20px;">
          <div class="small reset-password " style="margin-top: 6px;"><a routerLink="/guest/otp-login" style="color: #4CAF50" href="">Login using
              OTP</a></div>
        </div>
        <div class="separator" style="color: rgba(166, 166, 166, 1);">or</div>
        <div class="d-flex justify-content-center styl2" >
          <div class="cardStyle">
            <button id="customGoogleButton" class="google-btn">
              <img src="assets/images/google_icon.png" alt="Google Logo" />
            </button>
          </div>
          <div class="cardStyle">
            <button class="btn btn-facebook" (click)="login()">
              <img src="assets/images/facebook_logo.png" class="img-fluid img-30">
            </button>
          </div>
        </div>
        <br>
        <div style="text-align: center;">
          <div class="signup">Don’t have an account?<a (click)="goToSignin()" style="color: #4CAF50 "> &nbsp;Sign Up</a></div>
        </div>
      <div class="terms" >
        By continuing, you agree to our Terms of Service and Privacy Policy
      </div>
      </div>
      
    </div>
    
   
  </div>
</div>
