import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SignupService } from '../../modules/admin/services/signup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OtpServiceService } from 'src/app/modules/admin/services/otp-service.service';
import { LoginService } from 'src/app/modules/admin/services/login.service';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';
import { ToastrService } from 'ngx-toastr';

declare const FB: any;
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  public signupForm: FormGroup;
  public storeList: any;
  public alertStoreData: any;
  public status: any;
  public result: any;
  public toastConfig: any;
  public isApiReturned: boolean;
  public categoryShowDialog = false;
  public submitted: any = false;
  googleData: any;
  fbData: Object;
  public adminStore: any
  // @HostListener('document:click', ['$event']) onDocumentClick(event) {
  //   this.categoryShowDialog = false;
  //  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public http: HttpClient,
    private signupService: SignupService,
    private otpService: OtpServiceService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private toastr: ToastrService,
  ) {
    this.signupForm = this.formBuilder.group(
      {
        name: ['', [Validators.required, Validators.pattern("^(?!.* {2})[a-zA-Z][a-zA-Z ]+[a-zA-Z]")]],
        email: ['', [Validators.required, Validators.pattern("[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}")]],
        mobile: ['', [Validators.required, Validators.pattern("^([+][9][1]|[9][1]|[0]){0,1}([6-9]{1})([0-9]{9})$")]],
        password: ['', [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!%*?&#_])[A-Za-z\\d$@$!%*?&#_]{8,}$")]],
        confirm_password: ['', Validators.required]
      },
      {
        validators: this.Mustmatch('password', 'confirm_password')
      }
    )
  }

  ngOnInit() {
    this.adminStore = this.route.snapshot.queryParamMap.get('storeId');
    localStorage.setItem('adminStore', this.adminStore)
    // @ts-ignore
    window.onGoogleLibraryLoad = () => {
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: '351984361500-re7l8uu0o5uvvp2jrdep73iaf6ajss5j.apps.googleusercontent.com',
        callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true
      });
      // @ts-ignore
      google.accounts.id.renderButton(
        // @ts-ignore
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "large", width: 100 }
      );
      // @ts-ignore
      google.accounts.id.prompt((notification: PromptMomentNotification) => { });
    };
  }

  async handleCredentialResponse(response: CredentialResponse) {
    this.googleData = JSON.parse(atob(response.credential.split('.')[1]))
    this.googleSignUP(this.googleData.email, this.googleData.given_name)
  }

  showDialog() {
    this.categoryShowDialog = !this.categoryShowDialog;
  }


  signupFormSubmit() {

    if (!this.signupForm.valid) {
      this.submitted = true
    } else {
      if (this.adminStore == null || this.adminStore == undefined || this.adminStore == '') {
        localStorage.setItem('signupName', this.signupForm.value.name)
        localStorage.setItem('signupEmail', this.signupForm.value.email)
        localStorage.setItem('LoginPhoneNumber', this.signupForm.value.mobile)
        localStorage.setItem('userName', this.signupForm.value.name,)
        let data = {
          name: this.signupForm.value.name,
          mobile: this.signupForm.value.mobile,
          email: this.signupForm.value.email,
          password: this.signupForm.value.password,
        }
        this.signupService.createStore(data).subscribe(
          (res: any) => {
            console.error('res creating store:', res);
            localStorage.setItem('userName', this.signupForm.value.name,)
            this.alertStoreData = res.user.response;
            this.status = this.alertStoreData.status;
            this.toastConfig = {};
            this.toastConfig.error = true;
            this.toastConfig.result = 'Account created successfully';
            this.result = 'Account Created, Your Account Id : ' + this.alertStoreData.result.uid;
            localStorage.setItem('otpPage', "2");
            localStorage.setItem('userId', res.user.response.result.uid);
            this.router.navigate(['/guest/verify_otp'])
            this.alertStoreData.result = 'Account Created, Your Account Id : ' + this.alertStoreData.result.uid;
            this.copyTextSuccess(this.alertStoreData.result)
            this.signupForm.reset();
          },
          (error: HttpErrorResponse) => {
            this.copyTextError(error.error.message)
            this.signupForm.reset();
            console.error('Error creating store:', error.error.message);
          }
        );
      } else {
        let data = {
          storeId: this.adminStore,
          name: this.signupForm.value.name,
          mobile: this.signupForm.value.mobile,
          email: this.signupForm.value.email,
          password: this.signupForm.value.password,
          confirm_password: this.signupForm.value.confirm_password
        }
        this.signupService.inviteSignup(data).subscribe((res: any) => {
          if (res.status == 200) {
            localStorage.setItem('userName', res.data.name)
            localStorage.setItem('userId', res.data.unqId)
            localStorage.setItem('userCategory', res.data.userCategory)
            this.router.navigate(['/guest/vendor']);
          } else {
            this.copyTextWar('Email or Phone number already exist')
          }
        })
      }
    }

  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  public toastClosed(e): void {
    if (e) {
      this.isApiReturned = false;
    }
  }


  Mustmatch(password: any, confirm_password: any) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswornControl = formGroup.controls[confirm_password]

      if (confirmPasswornControl.errors && confirmPasswornControl.errors['Mustmatch']) {
        return;
      }

      if (passwordControl.value !== confirmPasswornControl.value) {
        confirmPasswornControl.setErrors({ Mustmatch: true })
      } else {
        confirmPasswornControl.setErrors(null);
      }
    }
  }



  showpassword = false;
  showpassword1 = false;
  toggleShow() {
    this.showpassword = !this.showpassword
  }
  toggleShow1() {
    this.showpassword1 = !this.showpassword1
  }


  googleSignUP(email: any, name: any) {
    this.otpService.googleSignIn(email, name).subscribe((res: any) => {

      if (res.status == 200) {
        this.router.navigate(['/admin']).then(() => window.location.reload());
      }
    })
  }

  async login() {
    FB.login(async (result: any) => {
      this.loginService.getFacebookData(result.authResponse.userID, result.authResponse.accessToken).subscribe((res) => {
        this.fbData = res

        this.facebookSignUP(this.fbData)
      })
    }, { scope: 'email' });
  }

  facebookSignUP(data) {
    this.otpService.facebookSignIn(data.email, data.name).subscribe((res: any) => {
      if (res.status == 200) {
        this.router.navigate(['/admin']).then(() => window.location.reload());
      }
    })
  }


  goToLogin() {
    this.router.navigate(['/guest']).then(() => window.location.reload());
  }

  copyTextWar(data: any) {
    this.toastr.warning(data, '', {
      timeOut: 3000,
      positionClass: 'toast-top-center'
    });
  }

  copyTextError(data: any) {
    this.toastr.error(data, '', {
      timeOut: 3000,
      positionClass: 'toast-top-center'
    });
  }

  copyTextSuccess(data: any) {
    this.toastr.success(data, '', {
      timeOut: 3000,
      positionClass: 'toast-top-center'
    });
  }

}
