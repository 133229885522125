import { Component, OnInit, Input, ElementRef, HostListener } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { StoreDetailsService } from 'src/app/modules/admin/services/store-details.service';
import { StoreDatasService } from 'src/app/store-datas.service';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {

  // @HostListener('document:click', ['$event'])
  public categoryShowDialog: boolean = false;
  public displayBannerDelete = 'none';
  public showPopup: any
  public hideButtons: boolean;
  @Input() config: any;
  @Input() data: any;
  @Input() DBoy: any;
  @Input() itemsPerPage: number;
  @Input() currentPage: number;

  @Output() fieldClicked = new EventEmitter<any>(); // Event emitter for field clicks
  @Output() userDetail = new EventEmitter<any>();
  @Output() orderDetail = new EventEmitter<any>();
  @Output() trackIdEdit = new EventEmitter<any>();
  @Output() selectDBoy = new EventEmitter<any>();
  @Output() statusChange = new EventEmitter<any>();
  @Output() newItemEvent = new EventEmitter<string>();
  @Output() dataForDelete = new EventEmitter<string>();
  @Output() dataForShare = new EventEmitter<string>();
  @Output() dataForEdit = new EventEmitter<string>();
  @Output() couponDelete = new EventEmitter<string>();
  @Output() couponEdit = new EventEmitter<string>();
  @Output() deliveryDelete = new EventEmitter<string>();
  @Output() deliveryEdit = new EventEmitter<string>();
  // @Output() assignDboy = new EventEmitter<string>();
  @Output() userStatusChange = new EventEmitter<string>();
  @Output() bannerDelete = new EventEmitter<string>();
  @Output() bannerEdit = new EventEmitter<string>();
  @Output() catalogEdit = new EventEmitter<string>();
  @Output() catalogStatus = new EventEmitter<string>();
  @Output() categoryEdit = new EventEmitter<string>();
  @Output() categoryDelete = new EventEmitter<string>();
  @Output() subCategoryEdit = new EventEmitter<string>();
  @Output() subCategoryDelete = new EventEmitter<string>();
  @Output() subCategoryOpen = new EventEmitter<string>();
  @Output() addCategory = new EventEmitter<string>();
  @Output() catalogueRemove = new EventEmitter<string>();
  @Output() featureEdit = new EventEmitter<{ category: any, value: boolean }>();
  // @HostListener('document:click', ['$event'])
  //   documentClick(event: MouseEvent) {
  //     const index = this.showPopup.findIndex(x => x===true);

  //   }
  public subMenuList: any;
  public StoreID: any = localStorage.getItem('storeunqId');
  ispopUpShow: boolean;
  orderStatus: string;
  constructor(private storeDetailsService: StoreDetailsService, private storeDatas: StoreDatasService) {
    this.storeDatas.storeDetails$.subscribe((data) => {
      this.StoreID = data;
      // this.storeMenuDetails()
    })
  }

  ngOnInit() {
    this.storeDatas.hideOrderStatus.subscribe((data) => {
      if (data == true) {
        this.hideButtons = true
      } else {
        this.hideButtons = false
      }
    })
  }
  ngAfterViewInit() {
  }

  handleFieldClick(field: string, row: any) {
    console.log('Field Clicked:', field);
    console.log('Row Data:', row);
    this.fieldClicked.emit({ field, row }); // Emit field and row data to parent
  }

  openModal(value: string, row: any) {
    this.statusChange.emit({ value, row });
    //this.newItemEvent.emit(value);
  }
  userDetilModal(value: any) {
    this.userDetail.emit(value);
  }
  selectBoy(value: string, row: string) {
    this.selectDBoy.emit({ value, row });
  }
  orderModal(row: any) {
    this.orderDetail.emit(row);
  }
  trackEditModal(row: any) {
    this.trackIdEdit.emit(row);
  }

  addPrombt(row) {
    this.addCategory.emit(row)
  }

  onUpdatingFeatured(row: any, event: any) {
    const isChecked = event.target as HTMLInputElement;
    const value = isChecked.checked
    this.featureEdit.emit({ value: value, category: row });
  }

  editPrombt(row) {
    this.catalogEdit.emit(row);
    this.deliveryEdit.emit(row);
    this.dataForEdit.emit(row);
    this.couponEdit.emit(row);
    this.bannerEdit.emit(row);
    this.categoryEdit.emit(row);
    this.subCategoryEdit.emit(row);
    this.showPopup = 'false'
  }
  deletePrombt(row) {
    this.dataForDelete.emit(row);
    this.couponDelete.emit(row);
    this.deliveryDelete.emit(row);
    this.bannerDelete.emit(row);
    this.categoryDelete.emit(row);
    this.subCategoryDelete.emit(row);
    this.catalogueRemove.emit(row);
    this.showPopup = 'false'
  }
  sharePrombt(row) {
    this.dataForShare.emit(row);
    this.showPopup = 'false'
  }
  catalogStatusChange(row) {
    this.catalogStatus.emit(row);
  }
  changeUserStatus(row) {
    this.userStatusChange.emit(row);
  }

  showDialog() {
    this.categoryShowDialog = !this.categoryShowDialog;
  }

  openBannerDeleteModal() {
    this.displayBannerDelete = 'block';
  }

  onClose() {
    this.displayBannerDelete = 'none';
  }


  openPopup(event: Event, index: number) {
    event.stopPropagation();

    if (this.showPopup === index) {
      this.showPopup = 'false';
    } else {
      this.showPopup = index.toString()
    }
  }
}
