import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
@Input() config: any = {};
  constructor(private router: Router) { 
    // this.config.data = [
    //   {label: 'Dashboard', isLink: true, url: '/admin/dashboard'},
    //   {label: 'Dashboard', isLink: false, url: ''},
    //   {label: 'Dashboard', isLink: false, url: ''},
    // ]
  }

  ngOnInit(): void {
  }

}
