import { Component,NgZone, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ResetPasswordService } from 'src/app/modules/admin/services/reset-password.service';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

  public resetPassword: FormGroup;
  displayValue: any;
  dataSplit: any;
  showHead: boolean;
  password: any;
  isApiReturned: boolean;
  toastConfig: any;
  public categoryShowDialog = false

  constructor(
    private ngZone: NgZone,
    private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private resetPasswordService: ResetPasswordService,
    private router: Router,
    private toastr: ToastrService,
  ) {
    const fullUrl = window.location.href;
    const splitUrl = fullUrl.split('$$');
    if (splitUrl.length > 1) {
      this.displayValue = splitUrl[1];
    } else {
      this.displayValue = null
    }
  }

  ngOnInit(): void {
    this.resetPassword = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#_])[A-Za-z\\d$@$!%*?&#_]{8,}$')]],
        confirm_password: ['', Validators.required],
        user: [this.displayValue, Validators.required]
      },
      {
        validators: this.Mustmatch('password', 'confirm_password')
      }
    )
  }

  showDialog() {
    this.categoryShowDialog = !this.categoryShowDialog;
  }

  public toastClosed(e): void {
    if (e) {
      this.isApiReturned = false;
    }
  }

  onClickReset() {
    if (this.resetPassword.invalid) {
      this.copyText('Please fill all fields', 'error')
    } else {
      this.password = this.resetPassword.value.password;
      this.resetPasswordService.resetPassword(this.password, this.displayValue).subscribe((res: any) => {
        this.isApiReturned = true;
        if (res.status == 200) {
          this.resetPassword.reset();
          this.toastConfig = res.message;
          this.copyText(res.message,'success')
          this.router.navigateByUrl('/guest')
        } else {
          this.toastConfig = res.result;
          this.copyText(res.message, 'error')
        }
      })
    }
  }

  copyText(data: any, type) {
    if (type == 'error') {
      this.toastr.error(data, '', {
        timeOut: 3000,
        positionClass: 'toast-top-center'
      });
    } else {
      this.toastr.success(data, '', {
        timeOut: 3000,
        positionClass: 'toast-top-center'
      });
    }
  }

  Mustmatch(password: any, confirm_password: any) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswornControl = formGroup.controls[confirm_password]

      if (confirmPasswornControl.errors && confirmPasswornControl.errors['Mustmatch']) {
        return;
      }

      if (passwordControl.value !== confirmPasswornControl.value) {
        confirmPasswornControl.setErrors({ Mustmatch: true })
      } else {
        confirmPasswornControl.setErrors(null);
      }
    }
  }

}
