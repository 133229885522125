<div class="pagination">
    <button (click)="previous()" [disabled]="localCurrentPage === 1">
        <div class="butduv">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
            </svg>
        </div>
        &nbsp;&nbsp;Previous&nbsp;&nbsp;
    </button>
    <ng-container *ngFor="let page of pageBlock">
        <button (click)="goToPage(page)" [class.active]="localCurrentPage === page.page">{{ page.page }}</button>
    </ng-container>
    <button (click)="next()" [disabled]="localCurrentPage === totalPages">
        Next&nbsp;&nbsp;
       <div class="butduv">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path d="M4 11h12.17l-5.59-5.59L12 4l8 8-8 8-1.41-1.41L16.17 13H4v-2z" />
        </svg>
       </div>
    </button>
</div>