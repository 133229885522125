<ul class="p-0 pl-4 pt-4 m-0 d-flex align-items-center" style="padding: 25px;">
    <li *ngFor="let breadcrumb of config.data; let last = last; let idx = index">
        <ng-container *ngIf="breadcrumb.isLink">
            <a [href]="breadcrumb.url">{{breadcrumb.label}}</a>
            <img src="assets/icons/Alt Arrow Down.svg" alt="">
        </ng-container>
        <ng-container *ngIf="!breadcrumb.isLink">
            {{breadcrumb.label}}
            <img *ngIf="!last" src="assets/icons/Alt Arrow Down.svg" alt="">
        </ng-container>
    </li>

</ul>